export class Company {
   public Id: string;
   public SimplifiedId: number;
   public ParentId: number;
   public ParentName: string;
   public PlanId: string;
   public PlanName: string;
   public AdditionalUsers: number;
   public AdditionalPos: number;
   public AdditionalPosWaiter: number;
   public AdditionalPosTotem: number;
   public Name: string;
   public Cnpj: string;
   public Cpf: string;
   public DocumentNumber: string;
   public IsPhysicalPerson: boolean;
   public HasDigitalCertificate: boolean;
   public DigitalCertificateExpirationDate: Date;
   public DigitalCertificateExpirationDateFormatted: string;
   public DigitalCertificateFriendlyName: string;
   public DigitalCertificatePassword: string;
   public DigitalCertificatePasswordConfirm: string;
   public CreatedOn: Date;
   public LastModifiedOn: Date;
   public CreatedBy: string;
   public LastModifiedBy: string;
   public TradingName: string;
   public LegalName: string;
   public OwnerName: string;
   public StateRegistration: string;
   public IsStateRegistrationExempt: boolean;
   public MunicipalRegistration: string;
   public BusinessLineId: string;
   public BusinessLineName: string;
   public CompanyLocationTypeId: string;
   public CompanyLocationTypeName: string;
   public TaxRegimeId: string;
   public TaxRegimeCode: number;
   public TaxRegimeName: string;
   public BusinessTypeId: string;
   public BusinessTypeName: string;
   public DefaultPriceTableId: string;
   public DefaultPriceTableName: string;
   public CompanyLogo?: string;
   public OpeningDate: Date;
   public OpeningDateFormatted: string;
   public ClosingDate: Date;
   public ClosingDateFormatted: string;
   public ExpirationDate: Date;
   public ExpirationDateFormatted: string;
   public RoyaltiesTaxValue: number;
   public RoyaltiesPercentage: number;
   public RoyaltiesDueDay: number;
   public FundMarketingValue: number;
   public FundMarketingPercentage: number;
   public FundMarketingDueDay: number;
   public HasStockControl: boolean;
   public IsActive: boolean;
   public HasSetupCompleted: boolean;
   public DefaultStockLocationId: string;
   public DefaultStockLocationName: string;
   public HasChildren: boolean;
   public HasLogo: boolean;
   public StateId: string;
   public StateName: string;
   public TagIds: string[];
   public SalesChannelIds: string[];
   public TagTexts: string;
   public SalesChannelTexts: string;
   public CompanyIdToCopyFiscalRules: number;
   public AddressLine1: string;
   public AddressLine2: string;
   public AddressNumber: string;
   public Neighborhood: string;
   public CityName: string;
   public StateAcronym: string;
   public CountryAcronym: string;
   public CountryName: string;
   public ZipCode: string;
   public Latitude: number;
   public Longitude: number;
   public Tags: CompanyTagQueryModel[];
   public SalesChannels: CompanySalesChannelQueryModel[];
   public Contacts: CompanyContactQueryModel[];
   public Addresses: CompanyAddressQueryModel[];
   public WeekBusinessHours: CompanyBusinessHoursQueryModel[];
   public DeliveryPeriods: CompanyDeliveryPeriodQueryModel[];
   public CompanyLocationType: any;

   public OperationTypeIds: string[];
   public OperationTypesTexts: string;
   public OperationTypes: CompanyOperationTypeItem[];

   constructor() {
      this.IsPhysicalPerson = false;
      this.HasDigitalCertificate = false;
      this.IsStateRegistrationExempt = false;
      this.HasStockControl = false;
      this.IsActive = true;
      this.HasSetupCompleted = false;
      this.HasChildren = false;
      this.HasLogo = false;
   }
}

export class CompanySignup {
   public Name: string;
   public DocumentNumber: string;
   public OwnerName: string;
   public Email: string;
   public EmailConfirm: string;
   public PhoneNumber: string;
   public Password: string;
   public IsTermsOfUseAccepted: boolean;
   public PasswordConfirm: string;
   public BusinessLineId: string;
   public BusinessLineName: string;
   public CompanyLocationTypeId: string;
   public CompanyLocationTypeName: string;
   public Address: CompanyConfigAddress;
   public OperationTypeIds: string[];

   constructor() {
      this.IsTermsOfUseAccepted = false;
      this.Address = new CompanyConfigAddress();
   }
}

export class CompanyConfigAddress {
   public CompanyAddressId: string;
   public FormattedAddress: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public AddressNumber: string;
   public Neighborhood: string;
   public CityName: string;
   public StateAcronym: string;
   public StateName: string;
   public CountryAcronym: string;
   public CountryName: string;
   public ZipCode: string;
   public Latitude: number;
   public Longitude: number;
   public AddressNotNumber: boolean;

   constructor() {
      this.AddressNotNumber = false;
   }
}

export class CompanyConfigBusinessHours {
   public WeekdayCode: number;
   public WeekdayName: string;
   public StartTime: string;
   public EndTime: string;
   public IsSunday: boolean;
   public IsMonday: boolean;
   public IsTueday: boolean;
   public IsWednesday: boolean;
   public IsThursday: boolean;
   public IsFriday: boolean;
   public IsSaturday: boolean;

   constructor() {
      this.IsSunday = false;
      this.IsMonday = false;
      this.IsTueday = false;
      this.IsWednesday = false;
      this.IsThursday = false;
      this.IsFriday = false;
      this.IsSaturday = false;
   }
}

export class CompanyConfigDeliveryFee {
   public RangeFrom: number;
   public MaxRange: number;
   public Value: number;
   public TimeToDeliver: number;
}

export class CompanyConfig {
   public CompanyId: number;
   public CompanyName: string;
   public HasSetupCompleted: boolean;
   public BusinessLineId: string;
   public BusinessLineName: string;
   public CompanyLocationTypeId: string;
   public CompanyLocationTypeName: string;
   public SalesChannelIds: string[];
   public IsUsingDeliveryService: boolean;
   public IsDeliveryEnabled: boolean;
   public IsPickupEnabled: boolean;
   public IsPaymentFormMoneyEnabled: boolean;
   public IsPaymentFormCreditEnabled: boolean;
   public IsPaymentFormDebitEnabled: boolean;
   public IsPaymentFormVoucherEnabled: boolean;
   public PhoneNumber: string;
   public HasCompanyLogo: boolean;
   public HasLogoToUpload: boolean;
   public FriendlyUrl: string;
   public FullFriendlyUrl: string;
   public CompanyLogoUrl: string;
   public ImportIfoodMenu: boolean;
   public IfoodMenuUrl: string;
   public Address: CompanyConfigAddress;
   public WeekBusinessHours: CompanyConfigBusinessHours[];
   public DeliveryFees: CompanyConfigDeliveryFee[];

   constructor() {
      this.HasSetupCompleted = false;
      this.IsUsingDeliveryService = false;
      this.IsDeliveryEnabled = false;
      this.IsPickupEnabled = false;
      this.IsPaymentFormMoneyEnabled = true;
      this.IsPaymentFormCreditEnabled = true;
      this.IsPaymentFormDebitEnabled = true;
      this.IsPaymentFormVoucherEnabled = true;
      this.HasCompanyLogo = true;
      this.ImportIfoodMenu = true;
      this.HasLogoToUpload = false;
      this.WeekBusinessHours = [];
      this.DeliveryFees = [
         {
            RangeFrom: 0,
            MaxRange: 2,
            TimeToDeliver: 30,
            Value: 0,
         },
      ];
   }
}

export class CompanyTagQueryModel {
   public Id: string;
   public Tag: string;
}

export class CompanySalesChannelQueryModel {
   public Id: string;
   public Name: string;
}

export class CompanyOperationTypeItem {
   public Id: string;
   public Name: string;
}

export class CompanyDigitalSertificateUpload {
   public HasDigitalCertificate: boolean;
   public Password: string;
   public File: string;
}

export class Companylogo {
   public File: string;
}

export class CompanyAddressAdd {
   public Id: string;
   public CompanyAddressId: string;
   public AddressTypeId: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public AddressNumber: string;
   public Neighborhood: string;
   public CityName: string;
   public StateAcronym: string;
   public StateName: string;
   public CountryAcronym: string;
   public CountryName: string;
   public ZipCode: string;
   public Latitude: number;
   public Longitude: number;
   public IsMain: boolean;
}
export class CompanyAddressWebService {
   public ZipCode: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public Neighborhood: string;
   public CityName: string;
   public StateAcronym: string;
   public IbgeCode: string;
   public GiaCode: string;
   constructor() {
      this.ZipCode = '';
      this.AddressLine1 = '';
      this.AddressLine2 = '';
      this.Neighborhood = '';
   }
}

export class CompanyContactQueryModel {
   public Id: string;
   public ContactTypeId: string;
   public ContactTypeName: string;
   public Value: string;
   public Name: string;
   constructor(tempId?: string) {
      this.Id = tempId;
   }
}

export class CompanyContactAdd {
   public Id: string;
   public CompanyContactId: string;
   public ContactTypeId: string;
   public Value: string;
   public Name: string;
}

export class CompanyAddressQueryModel {
   public Id: string;
   public AddressTypeId: string;
   public AddressTypeName: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public AddressNumber: string;
   public Neighborhood: string;
   public CityName: string;
   public StateAcronym: string;
   public StateName: string;
   public CountryAcronym: string;
   public CountryName: string;
   public ZipCode: string;
   public Latitude: number;
   public Longitude: number;
   public IsMain: boolean;
   constructor(tempId?: string, isMain: boolean = false) {
      this.Id = tempId;
      this.IsMain = isMain;
      this.ZipCode = ' ';
   }
}

export class CompanyFranchiseRequest {
   public Id: string;
   public RoyaltiesTaxValue: number;
   public RoyaltiesPercentage: number;
   public RoyaltiesDueDay: number;
   public FundMarketingValue: number;
   public FundMarketingPercentage: number;
   public FundMarketingDueDay: number;
   public CompanyId: number;
}

export class CompanyBusinessHoursQueryModel {
   public Id: string;
   public WeekdayCode: number;
   public WeekdayName: string;
   public StartTime: string;
   public EndTime: string;
   public IsSunday: boolean;
   public IsMonday: boolean;
   public IsTueday: boolean;
   public IsWednesday: boolean;
   public IsThursday: boolean;
   public IsFriday: boolean;
   public IsSaturday: boolean;
   constructor() {
      this.IsSunday = false;
      this.IsMonday = false;
      this.IsTueday = false;
      this.IsWednesday = false;
      this.IsThursday = false;
      this.IsFriday = false;
      this.IsSaturday = false;
   }
}

export class CompanyDeliveryPeriodQueryModel {
   public Id: string;
   public WeekdayCode: number;
   public WeekdayName: string;
   public StartTime: string;
   public EndTime: string;
   public IsSunday: boolean;
   public IsMonday: boolean;
   public IsTueday: boolean;
   public IsWednesday: boolean;
   public IsThursday: boolean;
   public IsFriday: boolean;
   public IsSaturday: boolean;
   constructor() {
      this.IsSunday = false;
      this.IsMonday = false;
      this.IsTueday = false;
      this.IsWednesday = false;
      this.IsThursday = false;
      this.IsFriday = false;
      this.IsSaturday = false;
   }
}

export class CompanyHoursQueryModel {
   public Id: string;
   public WeekdayCode: number;
   public WeekdayName: string;
   public StartTime: string;
   public EndTime: string;
   public IsSunday: boolean;
   public IsMonday: boolean;
   public IsTueday: boolean;
   public IsWednesday: boolean;
   public IsThursday: boolean;
   public IsFriday: boolean;
   public IsSaturday: boolean;
   constructor() {
      this.IsSunday = false;
      this.IsMonday = false;
      this.IsTueday = false;
      this.IsWednesday = false;
      this.IsThursday = false;
      this.IsFriday = false;
      this.IsSaturday = false;
   }
}

export class CompanyBusinessHoursSet {
   public Id: string;
   public CompanyId: number;
   public WeekBusinessHours: CompanyBusinessHoursQueryModel[];
}

export class CompanyDeliveryPeriodsSet {
   public Id: string;
   public CompanyId: number;
   public DeliveryPeriods: CompanyDeliveryPeriodQueryModel[];
}
