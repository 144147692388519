import { Injectable } from '@angular/core';

@Injectable()
export class NavigationService {
   constructor() {}

   // NewMenu
   //   New

   // ComingSoonMenu
   //   ComingSoon

   // TrialMenu
   //   Trial

   get(): any[] {
      return [
         {
            Name: 'Indicators',
            Icon: 'chart-line',
            Link: '/dashboard',
            Domain: 'Dashboard',
            TrialMenu: false,
            Items: [
               {
                  Name: 'DashboardDelivery',
                  Permission: 'DashboardDelivery',
                  HideInMenu: true,
               },
            ],
         },
         {
            Name: 'OrderCenter',
            Permission: 'OrderCenterPanel',
            Icon: 'moped',
            Link: '/centralpedidos',
            Items: [
               {
                  Name: 'DiskDelivery',
                  Permission: 'DiskDelivery',
                  HideInMenu: true,
               },
            ],
         },
         {
            Name: 'ProductMenu',
            Permission: 'Product',
            Icon: 'square-list',
            Link: '/menu',
            Items: [
               {
                  Name: 'Translations',
                  Permission: 'ProductMenuMultilanguage',
                  HideInMenu: true,
               },
            ],
         },
         {
            Name: 'Materials',
            Icon: 'box-full',
            Link: '',
            TrialMenu: false,
            Items: [
               {
                  Name: 'Category',
                  Permission: 'MarketingClassification',
                  Link: '/materiais/categoria',
               },
               {
                  Name: 'Products',
                  Permission: 'Product',
                  Link: '/materiais/produto',
                  Items: [
                     { Name: 'Prices', Permission: 'ProductPrice', HideInMenu: true },
                     {
                        Name: 'PriceHistory',
                        Permission: 'ProductPriceHistory',
                        HideInMenu: true,
                     },
                     {
                        Name: 'FiscalRules',
                        Permission: 'ProductFiscalRule',
                        HideInMenu: true,
                     },
                     {
                        Name: 'TaxGroups',
                        Permission: 'ProductTaxGroup',
                        HideInMenu: true,
                     },
                     {
                        Name: 'Printers',
                        Permission: 'ProductPrinter',
                        HideInMenu: true,
                     },
                     {
                        Name: 'Suppliers',
                        Permission: 'ProductSupplier',
                        HideInMenu: true,
                     },
                     { Name: 'Images', Permission: 'ProductImage', HideInMenu: true },
                     {
                        Name: 'Companies',
                        Permission: 'ProductStatus',
                        HideInMenu: true,
                     },
                     {
                        Name: 'ProductAvailabilityPeriod',
                        Permission: 'ProductAvailabilityPeriod',
                        HideInMenu: true,
                     },
                  ],
               },
               {
                  Name: 'ProductGroup',
                  Permission: 'ProductGroup',
                  Link: '/materiais/grupoproduto',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'ProductType',
                  Permission: 'ProductType',
                  Link: '/materiais/tipoproduto',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'ProductGoal',
                  Permission: 'ProductGoal',
                  Link: '/materiais/finalidadeproduto',
               },
               {
                  Name: 'Units',
                  Permission: 'ProductUnit',
                  Link: '/materiais/unidade',
               },
               {
                  Name: 'ProductNote',
                  Permission: 'ProductNote',
                  Link: '/materiais/observacaoproduto',
               },
               {
                  Name: 'PriceTable',
                  Permission: 'PriceTable',
                  Link: '/materiais/tabelapreco',
               },
               {
                  Name: 'PriceFamily',
                  Permission: 'PriceFamily',
                  Link: '/materiais/familiapreco',
               },
               {
                  Name: 'SolicitationType',
                  Permission: 'SolicitationType',
                  Link: '/materiais/tiposolicitacao',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'BatchChange',
                  Permission: 'BatchProducts',
                  Link: '/materiais/alteracaolote',
               },
            ],
         },
         {
            Name: 'Stock',
            Icon: 'cubes',
            Link: '',
            TrialMenu: false,
            Items: [
               {
                  Name: 'ManualMovement',
                  Permission: 'StockMovement',
                  Link: '/estoque/movimento',
               },
               {
                  Name: 'StockLocationShort',
                  Permission: 'StockLocation',
                  Link: '/estoque/localizacaoestoque',
               },
               {
                  Name: 'StockMovementCategoryShort',
                  Permission: 'StockMovementCategory',
                  Link: '/estoque/categoriamovimento',
               },
            ],
         },
         {
            Name: 'Pos',
            Icon: 'desktop',
            Link: '',
            TrialMenu: false,
            Items: [
               { Name: 'Pos', Permission: 'PointOfSale', Link: '/pdv/pdv' },
               { Name: 'Printer', Permission: 'Printer', Link: '/pdv/impressora' },
               {
                  Name: 'SatDevice',
                  Permission: 'SatDevice',
                  Link: '/pdv/dispositivosat',
               },
               {
                  Name: 'PaymentModeShort',
                  Permission: 'PaymentMode',
                  Link: '/pdv/modopagamento',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'SatModel',
                  Permission: 'SatModel',
                  Link: '/pdv/modelosat',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'PrinterModel',
                  Permission: 'PrinterModel',
                  Link: '/pdv/modeloimpressora',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'WeightBalanceModel',
                  Permission: 'WeightBalanceModel',
                  Link: '/pdv/modelobalanca',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'TicketGateModel',
                  Permission: 'TicketGateModel',
                  Link: '/pdv/modelocatraca',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'PrinterType',
                  Permission: 'PrinterType',
                  Link: '/pdv/tipoimpressora',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'OrderOrigin',
                  Permission: 'OrderOrigin',
                  Link: '/pdv/origempedido',
               },
               {
                  Name: 'MovementTypePos',
                  Permission: 'MovementType',
                  Link: '/pdv/tipomovimento',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'OperationReason',
                  Permission: 'OperationReason',
                  Link: '/pdv/motivooperacao',
               },
               {
                  Name: 'InstallerConfigShort',
                  Permission: 'InstallerConfig',
                  Link: '/pdv/instaladorservicos',
                  KcmsUserOnly: true,
               },
            ],
         },
         {
            Name: 'Financial',
            Icon: 'sack-dollar',
            Link: '',
            TrialMenu: false,
            Items: [
               {
                  Name: 'PaymentFormGroupShort',
                  Permission: 'PaymentFormGroup',
                  Link: '/financeiro/grupoformapagamento',
               },
               {
                  Name: 'PaymentForm',
                  Permission: 'PaymentForm',
                  Link: '/financeiro/formapagamento',
               },
               {
                  Name: 'PaymentTerm',
                  Permission: 'PaymentTerm',
                  Link: '/financeiro/condicaopagamento',
               },
               {
                  Name: 'PixIntegration',
                  Permission: 'PixIntegration',
                  Link: '/financeiro/pixonline',
                  Items: [
                     {
                        Name: 'PixIntegrationBalance',
                        Permission: 'PixIntegrationBalance',
                        HideInMenu: true,
                     },
                     {
                        Name: 'PixIntegrationTransaction',
                        Permission: 'PixIntegrationTransaction',
                        HideInMenu: true,
                     },
                     {
                        Name: 'PixIntegrationRefundPayment',
                        Permission: 'PixIntegrationRefundPayment',
                        HideInMenu: true,
                     },
                     {
                        Name: 'PixIntegrationCashOut',
                        Permission: 'PixIntegrationCashOut',
                        HideInMenu: true,
                     },
                  ],
               },
               {
                  Name: 'CostCenter',
                  Permission: 'CostCenter',
                  Link: '/financeiro/centrocusto',
               },
               {
                  Name: 'Bank',
                  Permission: 'Bank',
                  Link: '/financeiro/banco',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'BankAccount',
                  Permission: 'BankAccount',
                  Link: '/financeiro/contabancaria',
               },
               {
                  Name: 'FinanceCategory',
                  Permission: 'FinanceCategory',
                  Link: '/financeiro/categoria',
               },
               {
                  Name: 'FinanceAccount',
                  Permission: 'FinanceAccount',
                  Link: '/financeiro/lancamentofinanceiro',
               },
               {
                  Name: 'CashierBalancingMaintenance',
                  Permission: 'CashierBalancingMaintenance',
                  Link: '/financeiro/manutencaofechamentocego',
               },
            ],
         },
         {
            Name: 'Fiscal',
            Icon: 'balance-scale',
            Link: '',
            TrialMenu: false,
            Items: [
               { Name: 'Nfe', Permission: 'Nfe', Link: '/fiscal/nfe' },
               {
                  Name: 'NfeEntry',
                  Permission: 'NfeEntry',
                  Link: '/fiscal/entradanota',
                  Items: [
                     {
                        Name: 'SefazDfeIntegration',
                        Permission: 'SefazDfeIntegration',
                        HideInMenu: true,
                     },
                  ],
               },
               {
                  Name: 'FiscalScenario',
                  Permission: 'FiscalScenario',
                  Link: '/fiscal/cenariofiscal',
               },
               {
                  Name: 'FiscalDocumentSeriesShort',
                  Permission: 'FiscalDocumentSeries',
                  Link: '/fiscal/seriedocumentofiscal',
               },
               {
                  Name: 'FiscalDocumentModelShort',
                  Permission: 'FiscalDocumentModel',
                  Link: '/fiscal/modelodocumentofiscal',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'FiscalOperationType',
                  Permission: 'FiscalOperationType',
                  Link: '/fiscal/tipooperacao',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'FiscalOperationTypeDetailShort',
                  Permission: 'FiscalOperationTypeDetail',
                  Link: '/fiscal/detalhetipooperacao',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'Ncm',
                  Permission: 'Ncm',
                  Link: '/fiscal/ncm',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'Cest',
                  Permission: 'Cest',
                  Link: '/fiscal/cest',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'Cfop',
                  Permission: 'Cfop',
                  Link: '/fiscal/cfop',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'CstIcms',
                  Permission: 'CstIcms',
                  Link: '/fiscal/csticms',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'CstIpi',
                  Permission: 'CstIpi',
                  Link: '/fiscal/cstipi',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'CstOrigin',
                  Permission: 'CstOrigin',
                  Link: '/fiscal/cstorigem',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'CstPisCofins',
                  Permission: 'CstPisCofins',
                  Link: '/fiscal/cstpiscofins',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'PisCofinsOperationNatureShort',
                  Permission: 'PisCofinsOperationNature',
                  Link: '/fiscal/naturezaoperacaopiscofins',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'FiscalFiles',
                  Permission: 'FiscalFiles',
                  Link: '/fiscal/arquivosfiscais',
               },
            ],
         },
         {
            Name: 'Reports',
            Icon: 'file-alt',
            Link: '',
            TrialMenu: false,
            NewMenu: true,
            Items: [
               {
                  Name: 'PosSales',
                  Link: '',
                  Items: [
                     {
                        Name: 'CouponsIssued',
                        Permission: 'CouponsIssuedReport',
                        Link: '/relatorio/cuponsemitidos',
                     },
                     {
                        Name: 'ItemsBySeller',
                        Permission: 'ItemsBySellerReport',
                        Link: '/relatorio/itensvendedor',
                     },
                     {
                        Name: 'SaleItemsByCashierBalancingReportShort',
                        Permission: 'SaleItemsByCashierBalancing',
                        Link: '/relatorio/itensfechamentocaixa',
                     },
                     {
                        Name: 'AbcCurveSales',
                        Permission: 'AbcCurveReport',
                        Link: '/relatorio/curvaabcvendas',
                     },
                     {
                        Name: 'SalesByPaymentFormShort',
                        Permission: 'SalesByPaymentForm',
                        Link: '/relatorio/vendasporformadepagamento',
                     },
                     {
                        Name: 'HourlySales',
                        Permission: 'HourlySalesReport',
                        Link: '/relatorio/vendashoras',
                     },
                     {
                        Name: 'BillingGenderProduct',
                        Permission: 'BillingGenderProduct',
                        Link: '/relatorio/vendasprodutopdv',
                     },
                  ],
               },
               {
                  Name: 'POSOperations',
                  Link: '',
                  Items: [
                     {
                        Name: 'Consolidation',
                        Permission: 'ConsolidationReport',
                        Link: '/relatorio/consolidacao',
                     },
                     {
                        Name: 'PosOperationEvent',
                        Permission: 'PosOperationEventReport',
                        Link: '/relatorio/eventosoperacoespos',
                     },
                     {
                        Name: 'OrderCheckHistoryCommandTableItems',
                        Permission: 'OrderCheckHistoryCommandTableItemsReport',
                        Link: '/relatorio/itenscomandamesa',
                     },
                     {
                        Name: 'OperationReason',
                        Permission: 'OperationReasonReport',
                        Link: '/relatorio/motivooperacao',
                     },
                     {
                        Name: 'Tip',
                        Permission: 'TipReport',
                        Link: '/relatorio/repique',
                     },
                     {
                        Name: 'Withdraw',
                        Permission: 'WithdrawReport',
                        Link: '/relatorio/sangria',
                     },
                  ],
               },
               {
                  Name: 'FinancialMenu',
                  Link: '',
                  NewMenu: true,
                  Items: [
                     {
                        Name: 'CustomerStatement',
                        Permission: 'CustomerStatementReport',
                        Link: '/relatorio/extratodecliente',
                     },
                     {
                        Name: 'BillingPisCofins',
                        Permission: 'BillingPisCofinsReport',
                        Link: '/relatorio/faturamentopisconfins',
                     },
                     {
                        Name: 'CashierBalancing',
                        Permission: 'CashierBalancing',
                        Link: '/relatorio/fechamentocaixa',
                     },
                     {
                        Name: 'Dre',
                        Permission: 'DreReport',
                        Link: '/relatorio/dre',
                        New: true,
                     },
                     {
                        Name: 'MonthlyResults',
                        Permission: 'MonthlyResultsReport',
                        Link: '/relatorio/resultadosmensais',
                     },
                     {
                        Name: 'RoyaltiesMarketingFundShort',
                        Permission: 'RoyaltiesMarketingFund',
                        Link: '/relatorio/royaltiesfundomarketing',
                     },
                     {
                        Name: 'ServiceCharge',
                        Permission: 'ServiceCharge',
                        Link: '/relatorio/taxadeservico',
                     },
                  ],
               },
               {
                  Name: 'StockMenu',
                  Link: '',
                  Items: [
                     {
                        Name: 'ProductStock',
                        Permission: 'ProductStock',
                        Link: '/relatorio/estoqueproduto',
                     },
                     {
                        Name: 'StockIndicators',
                        Permission: 'StockIndicatorsReport',
                        Link: '/relatorio/indicadoresestoque',
                     },
                     {
                        Name: 'StockMovementShort',
                        Permission: 'StockMovementReport',
                        Link: '/relatorio/movimentacaoestoque',
                     },
                     {
                        Name: 'StockPosition',
                        Permission: 'ProductStockPosition',
                        Link: '/relatorio/posicaoestoqueproduto',
                     },
                  ],
               },
               {
                  Name: 'OrdersMenu',
                  Link: '',
                  Items: [
                     {
                        Name: 'Motoboy',
                        Permission: 'MotoboyReport',
                        Link: '/relatorio/entregador',
                     },
                     {
                        Name: 'DeliveryBillingGenderProduct',
                        Permission: 'DeliveryBillingGenderProduct',
                        Link: '/relatorio/vendasprodutodelivery',
                     },
                     {
                        Name: 'DeliveryOrders',
                        Permission: 'DeliveryOrder',
                        Link: '/relatorio/pedidosdelivery',
                     },
                     {
                        Name: 'DeliveryOrdersByPaymentFormShort',
                        Permission: 'DeliveryOrdersByPaymentForm',
                        Link: '/relatorio/pedidosdeliveryformaspagamento',
                     },
                  ],
               },
            ],
         },
         {
            Name: 'Marketing',
            Icon: 'bullhorn',
            Link: '',
            TrialMenu: false,
            Items: [
               {
                  Name: 'DiscountCoupon',
                  Permission: 'DiscountCoupon',
                  Link: '/marketing/cupomdesconto',
               },
               {
                  Name: 'Review',
                  Permission: 'Review',
                  Link: '/marketing/avaliacoes',
               },
               {
                  Name: 'CreateArts',
                  Link: '/marketing/geradordeartes',
               },
               {
                  Name: 'Promotion',
                  Permission: 'Promotion',
                  Link: '/marketing/promocao',
               },
            ],
         },
         {
            Name: 'Relationship',
            Icon: 'people-carry',
            Link: '',
            TrialMenu: false,
            Items: [
               {
                  Name: 'Waitlist',
                  Permission: 'WaitlistCustomer',
                  Link: '/relacionamento/listaespera',
               },
               {
                  Name: 'SmsSender',
                  Permission: 'SmsSender',
                  Link: '/relacionamento/enviosms',
                  KcmsUserOnly: true,
               },
            ],
         },
         {
            Name: 'BasicRegistrations',
            Icon: 'folder-open',
            Link: '',
            TrialMenu: false,
            Items: [
               {
                  Name: 'Company',
                  Permission: 'Company',
                  AccessType: 'Update',
                  Link: '/cadastrosbase/empresa',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'Accountant',
                  Permission: 'Accountant',
                  Link: '/cadastrosbase/contador',
               },
               {
                  Name: 'Collaborator',
                  Permission: 'Collaborator',
                  Link: '/cadastrosbase/colaborador',
               },
               {
                  Name: 'Customer',
                  Permission: 'Customer',
                  Link: '/cadastrosbase/cliente',
               },
               {
                  Name: 'Supplier',
                  Permission: 'Supplier',
                  Link: '/cadastrosbase/fornecedor',
               },
               {
                  Name: 'Motoboy',
                  Permission: 'Motoboy',
                  Link: '/cadastrosbase/motoboy',
               },
               {
                  Name: 'ShippingCompany',
                  Permission: 'ShippingCompany',
                  Link: '/cadastrosbase/transportadora',
               },
               {
                  Name: 'ServiceCharge',
                  Permission: 'ServiceCharge',
                  Link: '/cadastrosbase/taxaservico',
               },
               {
                  Name: 'User',
                  Permission: 'UserProfile',
                  Link: '/cadastrosbase/usuario',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'CompanyTag',
                  Permission: 'CompanyTag',
                  Link: '/cadastrosbase/tagempresa',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'BusinessLine',
                  Permission: 'BusinessLine',
                  Link: '/cadastrosbase/ramoatuacao',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'LocationType',
                  Permission: 'CompanyLocationType',
                  Link: '/cadastrosbase/tipolocalizacao',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'SalesChannel',
                  Permission: 'SalesChannel',
                  Link: '/cadastrosbase/canalvendas',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'DigitalCertificate',
                  Permission: 'DigitalCertificate',
                  Link: '/cadastrosbase/certificadodigital',
                  KcmsUserOnly: true,
               },
            ],
         },
         {
            Name: 'StoreResource',
            Icon: 'store',
            Link: '/lojaaplicativos',
            Items: [
               {
                  Name: 'TotemConfig',
                  Permission: 'TotemConfig',
                  HideInMenu: true,
               },
               {
                  Name: 'StockControlConfig',
                  Permission: 'StockControlConfig',
                  HideInMenu: true,
               },
               {
                  Name: 'OmieIntegration',
                  Permission: 'OmieIntegration',
                  HideInMenu: true,
               },
               {
                  Name: 'AcomIntegration',
                  Permission: 'AcomIntegration',
                  HideInMenu: true,
               },
               {
                  Name: 'AlloyIntegration',
                  Permission: 'AlloyIntegration',
                  HideInMenu: true,
               },
               {
                  Name: 'StoneConnect',
                  Permission: 'StoneConnect',
                  HideInMenu: true,
               },
               {
                  Name: 'AnotaAiIntegration',
                  Permission: 'AnotaAiIntegration',
                  HideInMenu: true,
               },
               {
                  Name: 'GoomerTabletName',
                  Permission: 'GoomerTabletIntegration',
                  HideInMenu: true,
               },
               {
                  Name: 'PixIntegration',
                  Permission: 'PixIntegration',
                  HideInMenu: true,
                  Items: [
                     {
                        Name: 'PixIntegrationBalance',
                        Permission: 'PixIntegrationBalance',
                        HideInMenu: true,
                     },
                     {
                        Name: 'PixIntegrationTransaction',
                        Permission: 'PixIntegrationTransaction',
                        HideInMenu: true,
                     },
                     {
                        Name: 'PixIntegrationRefundPayment',
                        Permission: 'PixIntegrationRefundPayment',
                        HideInMenu: true,
                     },
                     {
                        Name: 'PixIntegrationCashOut',
                        Permission: 'PixIntegrationCashOut',
                        HideInMenu: true,
                     },
                  ],
               },
            ],
         },
         {
            Name: 'Configuration',
            Icon: 'cogs',
            Link: '',
            TrialMenu: false,
            Items: [
               {
                  Name: 'General',
                  Permission: 'Parameterization',
                  Link: '/configuracao/configuracaogeral',
               },
               {
                  Name: 'Company',
                  Permission: 'CompanyBaseData',
                  Link: '/configuracao/empresa',
                  Items: [
                     {
                        Name: 'Contacts',
                        Permission: 'CompanyContact',
                        HideInMenu: true,
                     },
                     {
                        Name: 'Addresses',
                        Permission: 'CompanyAddress',
                        HideInMenu: true,
                     },
                     {
                        Name: 'DirectSaleBusinessHours',
                        Permission: 'CompanyBusinessHours',
                        HideInMenu: true,
                     },
                     {
                        Name: 'DeliverySaleBusinessHours',
                        Permission: 'CompanyDeliveryPeriod',
                        HideInMenu: true,
                     },
                  ],
               },
               {
                  Name: 'OrderCenter',
                  Permission: 'OrderCenterConfig',
                  Link: '/configuracao/configuracaocentralpedidos',
                  Items: [
                     {
                        Name: 'DeliveryServiceConfig',
                        Permission: 'OrderCenterDeliveryServiceConfig',
                        HideInMenu: true,
                     },
                     {
                        Name: 'IFoodConfig',
                        Permission: 'OrderCenterIfoodConfig',
                        HideInMenu: true,
                     },
                     {
                        Name: 'GoomerConfig',
                        Permission: 'OrderCenterGoomerConfig',
                        HideInMenu: true,
                     },
                  ],
                  HideInMenu: true,
               },
               {
                  Name: 'DeliveryService',
                  Permission: 'DeliveryServiceConfig',
                  Link: '/configuracao/configuracaopedemaisdelivery',
                  Items: [
                     {
                        Name: 'WelcomePage',
                        Permission: 'DeliveryServiceConfigWelcomePage',
                        HideInMenu: true,
                     },
                     {
                        Name: 'DeliveryServiceConfigFreeDeliveryFee',
                        Permission: 'FreeDeliveryFeeConfig',
                        HideInMenu: true,
                     },
                  ],
                  HideInMenu: true,
               },
               {
                  Name: 'MenuService',
                  Permission: 'MenuServiceConfig',
                  Link: '/configuracao/configuracaopedemaismenu',
                  Items: [
                     {
                        Name: 'WelcomePage',
                        Permission: 'MenuServiceConfigWelcomePage',
                        HideInMenu: true,
                     },
                     {
                        Name: 'MenuServiceConfigWaiter',
                        Permission: 'MenuServiceConfigWaiter',
                        HideInMenu: true,
                     },
                     {
                        Name: 'CheckerApp',
                        Permission: 'MenuServiceConfigChecker',
                        HideInMenu: true,
                     },
                  ],
                  HideInMenu: true,
               },
               {
                  Name: 'OrderCheckConfig',
                  Permission: 'OrderCheckConfig',
                  Link: '/configuracao/comandaemesa',
                  HideInMenu: true,
               },
               {
                  Name: 'AccessGroups',
                  Permission: 'Group',
                  Link: '/configuracao/grupo',
               },
               {
                  Name: 'Alerts',
                  Permission: 'AlertBox',
                  Link: '/configuracao/alerta',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'Plans',
                  Permission: 'Plan',
                  Link: '/configuracao/plano',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'Notice',
                  Permission: 'Notice',
                  Link: '/configuracao/notificacao',
                  KcmsUserOnly: true,
               },
               { Name: 'ChangePlan', Link: '/trocarplano', HideInMenu: true },
               { Name: 'Trial', HideInMenu: true },
            ],
         },
         {
            Name: 'Admin',
            Icon: 'user-shield',
            Link: '',
            KcmsUserOnly: true,
            Items: [
               { Name: 'Trial', Link: '/admin/trial', KcmsUserOnly: true },
               {
                  Name: 'PreRegisteredProduct',
                  Permission: 'PreRegisteredProduct',
                  Link: '/admin/produtosprecadastrados',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'PaymentIntegrationManager',
                  Permission: 'PaymentIntegrationManager',
                  Link: '/admin/meiospagamento',
               },
               {
                  Name: 'WhatsAppMessageConfig',
                  Permission: 'WhatsAppMessageConfig',
                  Link: '/admin/mensagemwhatsapp',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'PixIntegration',
                  Permission: 'PixIntegration',
                  Link: '/admin/pixautomatico',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'PixPaymentGetErrors',
                  Permission: 'PixIntegration',
                  Link: '/admin/errospix',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'DreCategorySummaryCategoryName',
                  Permission: 'DreCategory',
                  Link: '/admin/drecategoria',
                  KcmsUserOnly: true,
               },
               {
                  Name: 'PaymentFormIntegrationTitle',
                  Permission: 'PaymentFormIntegration',
                  HideInMenu: true,
               },
            ],
         },
         {
            Name: 'Sync',
            Icon: 'sync-alt',
            Link: '',
            TrialMenu: false,
            Items: [
               {
                  Name: 'DataSync',
                  Permission: 'DataSync',
                  Link: '/sincronizacao/enviocarga',
               },
               {
                  Name: 'SyncManagementShort',
                  Permission: 'SyncManagement',
                  Link: '/sincronizacao/gerenciamento',
               },
            ],
         },
         {
            Name: 'Help',
            Icon: 'question-circle',
            Link: '',
            Items: [
               {
                  Name: 'ProductNews',
                  Link: 'https://ajuda.kcms.com.br/novidades',
                  IsSupport: true,
               },
               {
                  Name: 'HelpCenter',
                  Link: 'http://ajuda.kcms.com.br',
                  IsSupport: true,
               },
               {
                  Name: 'Downloads',
                  Link: 'https://www.kcms.com.br/downloads/?utm_source=erp&utm_medium=menu_lateral&utm_campaign=downloads',
                  IsSupport: true,
               },
               {
                  Name: 'Blog',
                  Link: 'http://www.kcms.com.br/blog?utm_source=erp&utm_medium=menu&utm_campaign=blog',
                  IsSupport: true,
               },
               {
                  Name: 'Suggestions',
                  Link: 'https://forms.office.com/r/MtGy0X7pkM',
                  IsSupport: true,
               },

               { Name: 'Support', Permission: 'Support', IsSupport: true },
            ],
         },
      ];
   }
}
